
const modal = document.getElementById('modal');
const html = document.getElementById('html');
let link = "";
let data = "";
let title = "";

const toggle = (e) => {
  link = encodeURI(e.target.parentNode.parentNode.querySelector('a').href);
  title = e.target.parentNode.parentNode.querySelector('a').innerHTML;
  data = encodeURI(e.target.parentNode.dataset.text);
  let sharelinks = `
    <li><a href="https://wa.me/?text=${data} - ${link}">WhatsApp</a></li>
    <li><a href="https://www.facebook.com/sharer.php?u=${link}">Facebook</a></li>
    <li><a href="https://twitter.com/intent/tweet?text=${data}&url=${link}">Twitter</a></li>
    <li><a href="mailto:?subject=${data}&body=${data} - ${link}">Email</a></li>
    <li><a href="#" onclick="copy(link)">Copy Link</a></li>
  `;
  let heading = `Share ${title} link`
  modal.classList.toggle('hidden');
  html.classList.add('no-scroll');
  document.getElementById("minirigsharelinks").innerHTML = sharelinks;
  document.getElementById("minirigshareheading").innerHTML = heading;
}

const close = (e) => {
  modal.classList.add('hidden');
  html.classList.remove('no-scroll');
}

document.querySelectorAll('.share').forEach((element) => {
  element.addEventListener('pointerdown', toggle)  
});

document.querySelectorAll('.close').forEach((element) => {
  element.addEventListener('pointerdown', close)
});



function copy(link) {
  navigator.clipboard.writeText(link)
}